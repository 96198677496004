<template>
<div>
	<!-- <Contract /> -->
	<div class="breadcrumb" v-if="items && items.length > 0">
		<div class="breadcrumb__container">
			<div class="breadcrumb__items">
				<div class="breadcrumb__items--item" v-for="item in items">
					<router-link :to="item.to" v-if="item.to">
						<button class="btn" :key="item.title">
							{{item.title}}
						</button>
					</router-link>
					<button class="btn" :key="item.title" v-if="!item.to">
						{{item.title}}
					</button>
					<div class="arrow">
						/
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
</template>

<script>
import Contract from '@/components/Contract.vue'

	export default {
		props:['items'],
		components: {
			Contract
	}
}
</script>