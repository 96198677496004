<template>
	<div class="nav">
		<div class="navbar">
			<div class="navbar__logo">
				<router-link to="/">
					<img src="https://firebasestorage.googleapis.com/v0/b/mcaliber-bb61e.appspot.com/o/MCaliber%20Lgo_final.png?alt=media&token=605b9197-07ee-49bb-a898-a4641a88ae99" alt="" class="logo" />
				</router-link>
			</div>
			<div class="navbar__items">
				<router-link :to="{name: 'home'}" tag="button">Catalog</router-link>
				<!-- <router-link :to="{name: 'howToOrder'}" tag="button" class="hiddenSmAndDown">How To Order</router-link> -->
				<router-link :to="{name: 'contact'}" tag="button">Contact<span class="hiddenSmAndDown"> Us</span></router-link>
  			<router-link :to="{name: 'login'}" tag="button" v-if="!currentUser">Login</router-link>
				<router-link :to="{name: 'dashHome'}" tag="button" v-if="currentUser">Dashboard</router-link>
				<router-link :to="{name: 'register'}" tag="button" v-if="!currentUser" class="hiddenSmAndDown">Register</router-link>
				<!-- <router-link :to="{name: 'dashProducts'}" tag="button" v-if="userProfile && userProfile.access === 'admin'">Products</router-link> -->
				
				<!-- <button @click="logout()" v-if="currentUser">Logout</button> -->
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
	computed: {
    ...mapState(['currentUser', 'userProfile']),
  },
	methods: {
    logout() {
      this.$store.dispatch('logout')
    }
  },
}
</script>