<template>
  <div class="product">
  	<Nav />
  	<Breadcrumbs :items="crumbs" />
    <div class="product__main">
    	<transition name="fade">
    		<Loader v-if="!product" />
    	</transition>
    	<div v-if="product">
        <div class="flex flex-wrap">
          <div class="flex__cola flex-img">
            <!-- <img :src="product.pics[0]" alt=""> -->

            <!-- <div class="agile mt-5">
              <agile class="main" ref="main" :options="options1" :as-nav-for="asNavFor1">
                <div class="slide" v-for="(slide, index) in slides" :key="index" :class="`slide--${index}`"><img :src="slide"/></div>
              </agile>
              <agile class="thumbnails" ref="thumbnails" :options="options2" :as-nav-for="asNavFor2">
                <div class="slide slide--thumbnail" v-for="(slide, index) in slides" :key="index" :class="`slide--${index}`" @click="$refs.thumbnails.goTo(index)"><img :src="slide"/></div>
                <template slot="prevButton"><i class="fas fa-chevron-left"></i></template>
                <template slot="nextButton"><i class="fas fa-chevron-right"></i></template>
              </agile>
            </div> -->

            <div v-if="!product.stock || product.stock != true" class="text-center;">
              <img src="https://firebasestorage.googleapis.com/v0/b/medean95-4d3b4.appspot.com/o/outofstock.jpg?alt=media&token=9bd98cf1-3ca5-42c3-b62d-7c65b9ba5f30" alt="" style="width:80%; margin-left:10%;">
            </div>
            <div v-if="product.stock == true">
              <div class="agile" v-if="slides && slides.length >= 1">
                <agile class="main2" ref="main" :options="options1">
                  <div class="slide" v-for="(slide, index) in slides" :key="index" :class="`slide--${index}`"><img :src="slide"/></div>
                  <template slot="prevButton"><i class="fas fa-chevron-left"></i></template>
                  <template slot="nextButton"><i class="fas fa-chevron-right"></i></template>
                </agile>
                <!-- <agile class="thumbnails" ref="thumbnails" :options="options2" :as-nav-for="[$refs.main]">
                  <div class="slide slide--thumbnail" v-for="(slide, index) in slides" :key="index" :class="`slide--${index}`" @click="$refs.thumbnails.goTo(index)"><img :src="slide"/></div>
                    <template slot="prevButton"><i class="fas fa-chevron-left"></i></template>
                    <template slot="nextButton"><i class="fas fa-chevron-right"></i></template>
                </agile> -->
              </div>
            </div>

          </div>
          <div class="flex__colb">
            <h1 class="mb-1">{{product.part}}</h1>
            <p>{{product.description}}</p>
            <br />
            <p>Email info@m-caliber.com for ordering information.</p>
            <!-- <table>
              <tr>
                <td>Category:</td>
                <td>{{product.category}}</td>
              </tr>
              <tr>
                <td>Manufacturer:</td>
                <td>{{product.manufacturer}}</td>
              </tr>
              <tr>
                <td>Model:</td>
                <td>{{product.part}}</td>
              </tr>
              <tr>
                <td>Contract Line Item #</td>
                <td>{{product.lineItem}}</td>
              </tr>
              <tr v-if="product.approvalNumber">
                <td>NIOSH Approval Number:</td>
                <td>{{product.approvalNumber}}</td>
              </tr>
              <tr v-if="product.establishmentNumber">
                <td>FDA Registered Establishment Number:</td>
                <td>{{product.establishmentNumber}}</td>
              </tr>
              <tr>
                <td>Commodity Description:</td>
                <td>{{product.commodityDescription}}</td>
              </tr>
              <tr>
                <td>UNSPSC Code:</td>
                <td>{{product.unspsc}}</td>
              </tr>
              <tr>
                <td>Style:</td>
                <td>N95 ({{product.style}})</td>
              </tr>
              <tr>
                <td>Units Per Box:</td>
                <td>{{product.boxUnits}}</td>
              </tr>
              <tr>
                <td>Size:</td>
                <td>{{product.size}}</td>
              </tr>
              <tr>
                <td>Color:</td>
                <td>{{product.color}}</td>
              </tr>
              <tr>
                <td>Strap:</td>
                <td>{{product.strap}}</td>
              </tr>
              <tr v-if="product.manufacturer != '3M' && product.id != '2APrVQxBbk8WdU6e06KV'">
                <td>Government Discount Price 1M+ Units:</td>
                <td>${{product.contractPrice}}/ea</td>
              </tr>
              <tr v-if="product.manufacturer != '3M' && product.id != '2APrVQxBbk8WdU6e06KV'">
                <td>Government Discount Price 5M+:</td>
                <td>${{product.contractPrice5M}}/ea</td>
              </tr>
              <tr<tr v-if="product.manufacturer != '3M' && product.id != '2APrVQxBbk8WdU6e06KV'">
                <td>Government Discount Price 10M+:</td>
                <td>${{product.contractPrice10M}}/ea</td>
              </tr>
              <tr v-if="product.manufacturer != '3M' && product.id != '2APrVQxBbk8WdU6e06KV'">
                <td>Non-Government List Price 1M-5M Units:</td>
                <td><span class="strike">${{product.msrp}}/ea</span></td>
              </tr>
              <tr v-if="product.id == '2APrVQxBbk8WdU6e06KV'">
                <td></td>
                <td>Contract Price:</td>
                <td>Promotional Price:</td>
              </tr>
              <tr v-if="product.id == '2APrVQxBbk8WdU6e06KV'">
                <td>Government Discount Price 1M+ Units:</td>
                <td><span class="strike">$1.86/ea</span></td>
                <td>${{product.contractPrice}}/ea</td>
              </tr>
              <tr v-if="product.id == '2APrVQxBbk8WdU6e06KV'">
                <td>Government Discount Price 5M+:</td>
                <td><span class="strike">$1.79/ea</span></td>
                <td>${{product.contractPrice5M}}/ea</td>
              </tr>
              <tr v-if="product.id == '2APrVQxBbk8WdU6e06KV'">
                <td>Government Discount Price 10M+:</td>
                <td><span class="strike">$1.71/ea</span></td>
                <td>${{product.contractPrice10M}}/ea</td>
              </tr>
              <tr v-if="product.id == '2APrVQxBbk8WdU6e06KV'">
                <td>Non-Government List Price 1M-5M Units:</td>
                <td><span class="strike">${{product.msrp}}/ea</span></td>
                <td></td>
              </tr>
              <tr v-if="product.specs">
                <td>Download Product Specs (PDF):</td>
                <td><a :href="product.specs" target="_blank">Open File  <i class="ml-1 fad fa-external-link"></i></a></td>
              </tr>
              <tr v-if="product.manufacturer == 'Honeywell'">
                <td>Honeywell Certificate of Appointment:</td>
                <td><a href="https://firebasestorage.googleapis.com/v0/b/medeamp-deeef.appspot.com/o/CertificateofAppointment.pdf?alt=media&token=e0f2abd6-3655-441a-87ca-50978506926f" target="_blank">Open File  <i class="ml-1 fad fa-external-link"></i></a></td>
              </tr>




              
            </table> -->
            <!-- <div class="flex mt-4 justify-space-between">
              <router-link :to="{name: 'howToOrder'}" tag="button" class="btn btn__outlined">How to Order</router-link>
              <router-link :to="{name: 'login'}" tag="button" class="btn btn__primary" v-if="!currentUser">Account Login</router-link>
              <router-link :to="{name: 'newOrder'}" tag="button" class="btn btn__primary" v-if="currentUser">Place Order</router-link>
            </div> -->
          </div>
        </div>
    	</div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import Nav from '@/components/Nav.vue'
import Loader from '@/components/Loader.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'product',
  data () {
    return {
      asNavFor1: [],
      asNavFor2: [],
      options1: {
        dots: false,
        fade: true,
        navButtons: true,
      },
      options2: {
        autoplay: false,
        centerMode: true,
        dots: false,
        navButtons: true,
        slidesToShow: 3,
        // responsive: [
        //   {
        //     breakpoint: 600,
        //     settings: {
        //       slidesToShow: 3
        //     }
        //   },
          
        //   {
        //     breakpoint: 1000,
        //     settings: {
        //       navButtons: true
        //     }
        //   }
        // ],
      },
    }
  },
  created () {
    this.$store.dispatch("getProductFromId", this.$route.params.id);
  },
  // mounted () {
  //   this.asNavFor1.push(this.$refs.thumbnails)
  //   this.asNavFor2.push(this.$refs.main2)
  // },
  computed: {
    ...mapState(['currentUser', 'userProfile', 'customerProfile', 'product']),
    slides() {
      if (this.product.pics && this.product.pics.length) {
        return this.product.pics
      } else {
        return []
      }
    },
    crumbs () {
  		if (this.product) {
	      let product = this.product
	      let crumbsArray = []
	      let step1 = { title: "Catalog", to: { name: "home"}}
	      let step2 = { title: product.manufacturer, to: { name: "vendor"}}
	      let step3 = { title: product.part, to: false}
	      crumbsArray.push(step1)
	      // crumbsArray.push(step2)
	      crumbsArray.push(step3)
	      return crumbsArray
	    } else {
        return []
      }
    },
  },
  components: {
  	Nav,
  	Breadcrumbs,
  	Loader,
    Footer
  },
  beforeDestroy(){
    this.asNavFor1 = [],
    this.asNavFor2 = [],
    this.options1 = null,
    this.options2 = null,
    this.$store.dispatch('clearProduct')
  }
}
</script>
