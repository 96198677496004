<template>
	<div class="footer">
		<div class="footer__container pb-0 pt-5">
      <img src="https://firebasestorage.googleapis.com/v0/b/mcaliber-bb61e.appspot.com/o/MCaliber%20Lgo_final.png?alt=media&token=605b9197-07ee-49bb-a898-a4641a88ae99" alt="" class="logo" />
			
			<p><strong>Medea Inc. dba M-Caliber</strong></p>
			<!-- <p>California Certified Small Business</p> -->
  		<p>Office Phone: (925) 425-9282</p>
  		<p>Fax: 866-336-8969</p>
      <p>Email: info@m-caliber.com</p>
  		<br>
  		<!-- <address class="mb-3">
  			Medea Medical Products<br>
  			5653 Stoneridge Drive, Suite 119 <br>
  			Pleasanton, CA 94588
  		</address> -->
  		<p>
  			<router-link :to="{name: 'privacy'}">Privacy Policy</router-link> / <router-link :to="{name: 'terms'}">Terms of Use</router-link>
  		</p>
		</div>
		<div class="footer__container text-center">
			<p class="caption">© Medea Inc, 2021</p>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
	computed: {
    ...mapState(['currentUser', 'userProfile']),
  },
}
</script>